.App {
  text-align: center;
  background-color: #d8c9ffb0;
  height:100vh;
  width: 100vw;
  position: fixed;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.Center-Div{
  display: flex;
  justify-content: center;
  align-items: center;
}

.Scroll-Div{
  overflow-y: auto;
  height:100%
}

.Landing{
  height: 30vh;
  width:30vw;
  padding-top: 1%;
  padding-left: 35%;
}

.Enigma{
  height: 100%;
}

.Attribution{
  font-style: italic;
  font-size: x-small;
}

.Title{
  font-size: 72px;
  margin: 0;
}


.Home-Button{
  font-size: 45px;
    width: 60px;
    height: 60px;
    box-shadow: #7c7d7d 1px 2px 3px 1px;
    color: black;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    align-items: center;
    border: black 2px solid;
    display: flex;
    background-color: rgb(238, 236, 247);
    border-bottom-right-radius: 30%;
    position: fixed;
    z-index: 1000;
}

.Rounded-Div{
  font-size: xxx-large;
  width: 80%;
  height:50%;
  border-radius: 50px;
  box-shadow: #7c7d7d 1px 2px 3px 1px;
  color: black;
  text-decoration: none;
  text-align: center;
  justify-content: center;
  align-items: center;
  border: black 2px solid;
  display: block;
}

.letter-button{
  width: 60%;
  padding: 1%;
  border-radius: 20px;
  font-size: xx-large;
  background: white;
  font-style: italic;
  font-family: cursive;
  margin-top: 1%;
  cursor: pointer;
}

.letter {
  width: 90%;
  margin: 0 auto;
  padding: 1%;
  background-image: url("./old-letter.avif");
  background-size: cover;
  border-radius: 10px;
  font-family: 'cursive', serif;
  font-style: italic;
  line-height: 1.6;
  box-shadow: #e7e7e7 1px 1px 1px 1px
}

.letter-content {
  font-size: 18px;
  text-align: justify;
  text-indent: 30px;
}

.letter p:last-child {
  margin-top: 30px;
}

.letter br {
  content: '';
  display: block;
  margin-bottom: 10px;
}

.letter-button{
  width: 60%;
  font-style: italic;
  font-family: cursive;
  margin: 2%;
}
